
import {Component, Vue} from 'vue-property-decorator';
import DocumentUpload from "../components/DocumentUpload.vue";
import SubmissionService from "../service/submission-service";
import CustomPageTitle from "../components/CustomPageTitle.vue";
import {MimeTypes} from "../common/mime-types";
import FileUploaderInfoBox from "../components/FIleUploaderInfoBox.vue";
import {formatISODateTime} from '../filters/filters';

@Component({
    components: {
        CustomPageTitle,
        DocumentUpload,
        FileUploaderInfoBox
    },
    filters: {
        formatISODateTime,
    }
})
export default class CreationDocumentXmlSubmission extends Vue {

    public submissionService = new SubmissionService();

    private file: File | null = null;
    private acceptableMimeTypes = [MimeTypes.XML_TYPE]

    private lastSubmitSuccessful: boolean | null = null;

    get isLastSubmitSuccessful() {
        return this.lastSubmitSuccessful;
    }

    get isSendDisabled() {
        return !this.file || !this.file!!.text();
    }

    clearSubmissionData() {
        this.file = null;
        this.lastSubmitSuccessful = null;
    }

    private updateFile(value: File) {
        this.file = value;
    }

    private submit() {

        const xmlDoc = this.file!!.text()

        this.lastSubmitSuccessful = null;

        xmlDoc.then(
            (xml) => {
                this.submissionService.submitCreationDocumentDownloadPdf(xml, this.file!!.name)
                window.scrollTo(0, 0);
                this.clearSubmissionData()
            });
    }
}
